import { css } from '@emotion/react';
import { RIDITheme } from '@ridi-web/design-system/Styles';

export const titleStyle = (theme: RIDITheme) => css`
  width: 120px;
  height: 20px;
  border-radius: 6px;
  background: ${theme.colors.fillSkeletonElevated};
  margin-bottom: 12px;
`;

export const listContainerStyle = css`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 8px;
`;

export const itemAStyle = (theme: RIDITheme) => css`
  width: 56px;
  height: 36px;
  border-radius: 6px;
  background: ${theme.colors.fillSkeletonElevated};
`;

export const itemBStyle = (theme: RIDITheme) => css`
  width: 80px;
  height: 36px;
  border-radius: 6px;
  background: ${theme.colors.fillSkeletonElevated};
`;
