import * as styles from './SearchPopularBooksBSkeleton.styles';

const list = Array.from({ length: 10 }, () => null);

export const SearchPopularBooksSkeleton = () => (
  <div>
    <div css={styles.containerStyle}>
      {list.map((_, i) => (
        <div css={styles.bookContainerStyle} key={`item-${i.toString()}`}>
          <div css={styles.bookThumbnailStyle} />
          <div css={styles.bookOrderStyle} />
          <div css={styles.bookMetaDataStyle}>
            <div css={styles.bookTitleStyle} />
            <div css={styles.bookAuthorsStyle} />
          </div>
        </div>
      ))}
    </div>
  </div>
);
