import { FetchQueryOptions, QueryClient, QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from 'react-query';

import { HttpStatusCodes } from '@/base/constants/httpStatusCodes';
import type { APIGeneralError } from '@/queries/order/types';
import type { RequestError } from '@/utils/request';
import sendException, { isReportToSentry } from '@/utils/sentry/sendException';
import { ExceptionOption } from '@/utils/sentry/types';

import { useBaseQuery } from './base';

export type Key = QueryKey & ([queryName: string, params: object] | [queryName: string]);

type CreateQuery<TQueryFnData, TVariables, TError, TData, TKey extends Key> = TVariables extends undefined
  ? [
      <TSelectData = TData>(
        variables?: TVariables,
        options?: UseQueryOptions<TQueryFnData, TError, TSelectData, TKey>,
      ) => UseQueryResult<TSelectData, TError>,
      (
        queryClient: QueryClient,
        variables?: TVariables,
        options?: UseQueryOptions<TQueryFnData, TError, TData, TKey>,
      ) => Promise<TQueryFnData>,
    ]
  : [
      <TSelectData = TData>(
        variables: TVariables,
        options?: UseQueryOptions<TQueryFnData, TError, TSelectData, TKey>,
      ) => UseQueryResult<TSelectData, TError>,
      (
        queryClient: QueryClient,
        variables: TVariables,
        options?: UseQueryOptions<TQueryFnData, TError, TData, TKey>,
      ) => Promise<TQueryFnData>,
    ];

export const createQuery = <
  TQueryFnData,
  TVariables = undefined,
  TError = unknown,
  TData = TQueryFnData,
  TKey extends Key = Key,
>(
  key: (arg: TVariables) => TKey,
  fetch: (arg: TVariables) => QueryFunction<TQueryFnData, TKey>,
): CreateQuery<TQueryFnData, TVariables, TError, TData, TKey> =>
  [
    <TSelectData = TData>(variables?: TVariables, options?: UseQueryOptions<TQueryFnData, TError, TSelectData, TKey>) =>
      useBaseQuery<TQueryFnData, TError, TSelectData, TKey>(
        key(variables as TVariables),
        fetch(variables as TVariables),
        options,
      ),
    (queryClient: QueryClient, variables?: TVariables, options?: UseQueryOptions<TQueryFnData, TError, TData, TKey>) =>
      queryClient.fetchQuery(key(variables as TVariables), fetch(variables as TVariables), options),
  ] as CreateQuery<TQueryFnData, TVariables, TError, TData, TKey>;

type CreatePrefetchQuery<T, TVariables, TError, TData, TKey extends Key> = TVariables extends undefined
  ? (
      queryClient: QueryClient,
      variables?: TVariables,
      options?: FetchQueryOptions<T, TError, TData, TKey>,
    ) => Promise<void>
  : (
      queryClient: QueryClient,
      variables: TVariables,
      options?: FetchQueryOptions<T, TError, TData, TKey>,
    ) => Promise<void>;

export const createPrefetchQuery = <
  T = void,
  TVariables = undefined,
  TError = unknown,
  TData = T,
  TKey extends Key = Key,
>(
  key: (arg: TVariables) => TKey,
  fetch: (arg: TVariables) => QueryFunction<T, TKey>,
): CreatePrefetchQuery<T, TVariables, TError, TData, TKey> =>
  ((queryClient: QueryClient, variables: TVariables, options?: FetchQueryOptions<T, TError, TData, TKey>) =>
    queryClient.prefetchQuery(key(variables), fetch(variables), options)) as CreatePrefetchQuery<
    T,
    TVariables,
    TError,
    TData,
    TKey
  >;

type ErrorData<T = APIGeneralError> = T extends APIGeneralError ? T : unknown;

// promise 를 받아 try-catch 로 실행시켜 결과를 [error, result] 형태로 반환
export const handlePromiseFetcher = async <Error extends RequestError<ErrorData>, T>(promise: Promise<T>) => {
  try {
    const data = await promise;
    return [null, data] as [null, T];
  } catch (error) {
    return [error, null] as [Error, null];
  }
};

const SPECIFIC_ERROR_MESSAGES: Record<number, string> = {
  401: '로그인이 필요합니다.',
};

export const handleFetcherError = <T extends APIGeneralError>(
  error: RequestError<T>,
): {
  statusCode: number;
  message?: string;
  data?: T;
} => {
  const statusCode = error.response?.status || HttpStatusCodes.BadRequest;
  const message = SPECIFIC_ERROR_MESSAGES[statusCode] || error.response?.data?.message || error?.message;

  return {
    statusCode,
    message,
    data: error.response?.data,
  };
};

export const getCsrfTokenHeader = (csrfToken?: string) => (csrfToken ? { 'x-csrf-token': csrfToken } : {});

export async function fetchDataOrHandleError<T>(
  fetcher: () => Promise<T>,
  exceptionOption: ExceptionOption,
): Promise<T | { error: { statusCode: number; message: string } }> {
  const [error, data] = await handlePromiseFetcher(fetcher());

  if (error) {
    const { statusCode, message } = handleFetcherError(error);

    if (isReportToSentry(statusCode)) {
      sendException(error, exceptionOption);
    }

    return { error: { statusCode, message: message ?? 'An unknown error occurred' } };
  }

  return data;
}
