import * as styles from './SearchPopularBooksASkeleton.styles';

const list = Array.from({ length: 10 }, () => null);

export const SearchPopularBooksSkeleton = () => (
  <div css={styles.containerStyle}>
    {list.map((_, i) => (
      <div key={`data-${i.toString()}`} css={styles.bookContainerStyle}>
        <div css={styles.bookOrderStyle} />
        <div css={styles.bookTitleStyle} />
      </div>
    ))}
  </div>
);
