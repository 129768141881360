import { css } from '@emotion/react';
import { BreakPoint, orBelow, RIDITheme } from '@ridi-web/design-system/Styles';

export const containerStyle = css``;

export const keywordListContainerStyle = css`
  margin-bottom: 6px;
`;

export const keywordListTitleStyle = (theme: RIDITheme) => css`
  padding: 20px 16px 4px 16px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.01em;
  color: ${theme.colors.fillPrimary};
`;

export const keywordListStyle = css`
  display: flex;
  padding: 8px 16px;
  flex-wrap: wrap;
  overflow: hidden;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      max-height: 96px;
    `,
  )}
`;

const keywordItemContainer = (theme: RIDITheme) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  padding: 0 12px;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  margin-right: 8px;
  margin-bottom: 8px;
  color: ${theme.colors.fillPrimary};
  white-space: nowrap;
`;

export const keywordItemStyleA = (theme: RIDITheme) => css`
  ${keywordItemContainer(theme)}

  border: 1px solid ${theme.colors.grey300};

  &:hover {
    background-color: #fafafa;
  }
`;

export const keywordItemGerneStyle = (color: string) => css`
  color: ${color};
  font-size: 13px;
`;

export const keywordItemTagNameStyle = css`
  margin-left: 4px;
`;

export const keywordItemStyleB = (theme: RIDITheme) => css`
  ${keywordItemContainer(theme)}

  background: ${theme.colors.grey100};

  &:hover {
    background-color: #f0f0f0;
  }
`;

export const emptyMarginStyle = css`
  height: 12px;
`;
