import * as styles from './SearchPopularBooksTabsSkeleton.styles';

export const SearchPopularBooksTabsSkeleton = () => (
  <div css={styles.containerStyle}>
    <div css={styles.titleStyle} />
    <div css={styles.tabContainerStyle}>
      <div css={styles.tabItemStyle} />
      <div css={styles.tabItemStyle} />
      <div css={styles.tabItemStyle} />
      <div css={styles.tabItemStyle} />
    </div>
  </div>
);
